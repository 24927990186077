<template>
  <div v-if="store_Empreendimentos.fotos_galeria" id="EmpreendimentosGaleria">
    <v-carousel v-model="store_Empreendimentos.fotos_galeria.ordem">
      <v-carousel-item
        v-for="(galeria) in store_Empreendimentos.fotos_galeria"
        :key="galeria.ordem"
        :src="ImagemCaminhoCompleto(galeria.imagem_caminho)"
      >
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import { baseURL } from "../../services/API";
import store_Empreendimentos from "../Empreendimentos/store_Empreendimentos";

export default {
  data() {
    return {
      store_Empreendimentos : store_Empreendimentos,
    }
  },

  mounted() {
    //console.log('Montou !')
  },

  methods: {
    ImagemCaminhoCompleto(img_url) {
      var img = baseURL + img_url;
      return img
    },

  }

  }
</script>

<template>
  <div id="EmpreendimentoDetalhes" v-bind:style="{ 'background-color':COR_SECUNDARIA_2 }">
    <v-container class="page-container">
      <v-row class="first-row mt-10 pb-0">
        <v-col cols="12">
          <v-card class="mt-0 mb-6">
            <v-card-title> </v-card-title>
            <div
              class="d-flex container-title"
              style="background-color: #ffffff"
            >
              <div style="background-color: #ffffff" class="ml-4 mb-7 mr-10">
                <v-img
                  class="image-avatar"
                  max-width="150"
                  max-height="100"
                  contain
                  v-bind:src="store_Empreendimentos.empreend_selecionado.empreend_logo"
                ></v-img>
              </div>

              <div style="background-color: #ffffff">
                <div class="title-wrapper d-flex justify-space-between">
                  <h2 class="h3 main-title">
                    {{
                      store_Empreendimentos.empreend_selecionado.empreend_nome
                    }}
                  </h2>
                </div>
                <h3 class="subtitle-1 subtitle-adress">
                  <v-icon class="mr-1 mb-1 icon-address">mdi-map-marker</v-icon>
                  {{store_Empreendimentos.empreend_selecionado.empreend_endereco}},
                  {{store_Empreendimentos.empreend_selecionado.empreend_municipio
                      ? store_Empreendimentos.empreend_selecionado.empreend_municipio.nome
                      : null
                  }}
                  -
                  {{
                    store_Empreendimentos.empreend_selecionado
                      .empreend_municipio
                      ? store_Empreendimentos.empreend_selecionado
                          .empreend_municipio.uf
                      : null
                  }}
                </h3>
              </div>
            </div>
          </v-card>

          <v-card class="mb-3 d-block">
            <v-card-title>
              <h2 class="h5 font-weight-bold">Mapa do Empreendimento</h2>
              <v-spacer></v-spacer>
              <div>
                <v-btn 
                  :disabled="!store_Empreendimentos.empreend_selecionado.cod_empreendimento"
                  @click="NovoLead"
                  class="text-white text-none"
                  elevation="0"
                  :small="store_site.isMobileDevice">
                  <v-icon class="mr-1" color="green accent-2">mdi-eye</v-icon>
                  <span>Novo Lead</span>
                </v-btn>
              </div>
            </v-card-title>
            <MapaLotes class="mt-n10"/>
          </v-card>

          <v-card class="mt-7 galery"
                  >
            <v-card-title
              ><h2 class="h5 font-weight-bold">Galeria</h2></v-card-title
            >
            <div class="px-5 pb-5">
              <EmpreendimentosGaleria />
            </div>
          </v-card>
          <!-- <v-card v-else class="mt-9">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Galeria</h2></v-card-title
            >
            <div class="px-5 pb-5">
              <EmpreendimentosGaleria />
            </div>
          </v-card> -->

        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">

          <v-card class="mb-6 d-none">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Descrição</h2></v-card-title
            >

            <v-card-text>
              <p>
                Se você mora em Franca, sabe que morar em condomínio fechado 
                não é algo fácil. Os lotes são supervalorizados e as exigências 
                construtivas conduzem a execução de sobrados.
              </p>

              <p>
                Vendo tudo isso, a Parra Imobiliária, propõe uma super oportunidade. 
                Um condomínio residencial fechado, com a mesma infraestrutura e a 
                mesma tipologia dos novos residenciais fechados de Franca, 
                mas com preço imbatível.
              </p>

              <p>
                Desenvolvemos uma tipologia de terreno que viabiliza a construção 
                de casa térrea de alto padrão e generosa em seus ambientes.
              </p>
            </v-card-text>
          </v-card>

          <v-card class="mb-6 d-none">

            <!-- <v-card-title
              ><h2 class="h5 font-weight-bold">
                Detalhes do <br class="d-flex d-sm-none" />empreendimento
              </h2></v-card-title
            > -->
            <v-list class="pt-4">

              <v-card-subtitle
                ><h2 class="facilities h5 font-weight-bold">Facilidades</h2>
              </v-card-subtitle>

              <v-list-item class="wrapper-details-3">

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked</v-icon
                >
                <v-list-item-subtitle class="d-flex align-center">
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked </v-icon
                  >Condomínio Fechado
                </v-list-item-subtitle>

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked</v-icon
                >
                <v-list-item-subtitle class="d-flex align-center">
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked</v-icon
                  >
                  Estacionamento</v-list-item-subtitle
                >

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4">mdi-checkbox-marked</v-icon>
                <v-list-item-subtitle class="d-flex align-center">
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked</v-icon
                  >
                  Área de Laser Completa</v-list-item-subtitle
                >

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked</v-icon
                >

                <v-list-item-subtitle class="d-flex align-center">
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked</v-icon
                  >
                  Gás Central</v-list-item-subtitle
                >

                <v-list-item-subtitle
                  class="d-flex align-center d-flex d-sm-none"
                >
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked </v-icon
                  >Interfone</v-list-item-subtitle
                >

                <v-list-item-subtitle
                  class="d-flex align-center d-flex d-sm-none"
                >
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked</v-icon
                  >Proteiro Eletrônico</v-list-item-subtitle
                >

                <v-list-item-subtitle
                  class="d-flex align-center d-flex d-sm-none"
                >
                  <v-icon class="mr-2 d-flex d-sm-none" color="blue darken-4"
                    >mdi-checkbox-marked</v-icon
                  >Segurança Patrimonial
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item class="wrapper-details-4">
                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked
                </v-icon>

                <v-list-item-subtitle class="d-none d-sm-flex">
                  Interfone</v-list-item-subtitle
                >

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked</v-icon
                >

                <v-list-item-subtitle class="d-none d-sm-flex">
                  Proteiro Eletrônico</v-list-item-subtitle
                >

                <v-icon class="mr-2 d-none d-sm-flex" color="blue darken-4"
                  >mdi-checkbox-marked</v-icon
                >
                <v-list-item-subtitle class="d-none d-sm-flex">
                  Segurança Patrimonial
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-none">
          <v-card class="mb-6">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Vídeo do empreendimento</h2>
            </v-card-title>

            <div class="container">
              <div class="panel">
                  <iframe class="content px-5 pb-5"
                          src="https://www.youtube.com/embed/8gYlrOQ6DSc?autoplay=0&rel=0" frameborder="0"
                          allow="autoplay; accelerometer; encrypted-media; gyroscope; fullscreen; picture-in-picture"
                          allowfullscreen>
                  </iframe>
              </div>
            </div>
          </v-card>
        </v-col>

        <!-- Card -->
        <v-col cols="12" class="d-none">
          <v-card-title
            ><h2 class="h5 font-weight-bold">
              Empreendimentos <br class="d-flex d-sm-none" />Similares
            </h2>
          </v-card-title>

          <v-row class="px-4">
            <v-col
              v-for="(emp, index) of resultSimilares"
              v-bind:key="index"
              class="card-column px-"
              cols="12"
              xs="12"
              md="4"
              lg="4"
            >
              <v-card class="box box-card">
                <v-container>
                  <v-row class="card-row">
                    <!-- card area image -->
                    <v-col class="py-4 col-avatar box">
                      <!-- Card img -->
                      <div class="h-100 homes box">
                        <!-- <div class="d-flex justify-space-between">
                        <div
                          class="homes-tag featured body-2 d-flex justify-center align-center rounded"
                        >
                          Destaque
                        </div>
                        <div
                          class="homes-tag sale body-2  d-flex justify-center align-center rounded"
                        >
                          À Venda
                        </div>
                      </div> -->

                        <div class="mb-8">
                          <span
                            class="homes-tag featured caption rounded px-2 py-2"
                          >
                            Lotes
                            <span class="caption font-weight-bold"
                              >disponíveis</span
                            >: {{ emp.quant_unidades }}
                          </span>
                        </div>
                        <a
                          href="#"
                          class="homes-img d-flex justify-center align-center"
                          v-on:click="
                            flyTo(
                              fromLonLat([
                                emp.empreend_mapa_longitude,
                                emp.empreend_mapa_latitude,
                              ]),
                              emp.empreend_mapa_rotacao,
                              emp.empreend_mapa_zoom
                            )
                          "
                        >
                          <!-- <v-img
                              v-bind:src="emp.empreend_logo"
                              alt="home-1"
                              class="img-responsive"
                              max-width="100%"
                              max-height="275px"
                            ></v-img> -->
                          <v-avatar
                            class="profile"
                            color="white"
                            size="250"
                            tile
                          >
                            <v-img
                              v-bind:src="emp.empreend_logo"
                              max-width="250"
                              max-height="150"
                              contain
                            ></v-img>
                          </v-avatar>
                        </a>
                      </div>
                      <!-- End Card img -->
                      <!-- <div class="button-effect">
                          <a href="single-property-1.html" class="btn"
                            ><i class="fa fa-link"></i
                          ></a>
                          <a
                            href="https://www.youtube.com/watch?v=14semTlwyUY"
                            class="btn popup-video popup-youtube"
                            ><i class="fas fa-video"></i
                          ></a>
                          <a class="img-poppup btn"
                            ><i
                              class="fa fa-photo"
                              data-toggle="modal"
                              data-target="#exampleModalCenter"
                            ></i
                          ></a>
                        </div> -->
                    </v-col>
                    <!-- end card area image -->

                    <!-- card area content -->
                    <v-col class="col-content py-0">
                      <!-- address -->
                      <h3
                        @click="$router.push('/empreendimentodetalhe/' + emp.cod_empreendimento)"
                      >
                        <router-link
                          :to="'/empreendimentodetalhe/' + emp.cod_empreendimento"
                        >
                          {{ emp.empreend_nome }}
                        </router-link>
                      </h3>
                      <p class="homes-address mb-3">
                        <a href="single-property-1.html">
                          <v-icon class="mr-1 mb-1">mdi-map-marker</v-icon
                          ><span class="body-2"
                            >Av. Jaime Tellini, 5000, Jardim Botanico -
                            {{ emp.municipio }}</span
                          >
                        </a>
                      </p>
                      <!-- Card List -->
                      <ul class="homes-list clearfix mb-4 pl-0">
                        <!-- <li>
                        <span class="body-2">
                          <v-icon class="mr-2" large>mdi-checkbox-marked</v-icon
                          >Lotes Disponíveis: {{ emp.quant_unidades }}</span
                        >
                      </li> -->
                        <li>
                          <span class="body-2"
                            ><v-icon class="mr-2">mdi-lightbulb</v-icon
                            >Luz</span
                          >
                        </li>
                        <li>
                          <span class="body-2"
                            ><v-icon class="mr-2">mdi-shield-half-full</v-icon
                            >Segurança 24hr</span
                          >
                        </li>
                        <li>
                          <span class="body-2"
                            ><v-icon class="mr-2">mdi-road-variant</v-icon
                            >Asfalto</span
                          >
                        </li>
                      </ul>
                      <!-- End ard List -->
                      <div class="footer d-flex justify-space-between pb-3">
                        <div class="footer-value d-flex flex-column">
                          <!-- <v-icon class="mr-2" large>
                          mdi-cash-usd-outline</v-icon
                        > -->
                          <span class="font-small">Valores:</span>
                          <span class="font-weight-black"
                            >R$ {{ emp.preco_min }} a R$ {{ emp.preco_max }}
                          </span>
                        </div>
                        <div class="footer-value d-flex flex-column">
                          <!-- <v-icon class="fa fa-object-group mr-2 mb-1"
                          >mdi-vector-square</v-icon
                        > -->
                          <span class="font-small">Área:</span>
                          <span class="font-weight-black"
                            >{{ emp.area_min }}m² a {{ emp.area_max }}m²</span
                          >
                        </div>
                      </div>
                    </v-col>
                    <!-- end card area content -->
                    <!-- end Card -->
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

         <v-col cols="12">
          <v-card class="mb-6 d-none">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Agende uma visita</h2>
            </v-card-title>

            <v-form class="pa-5">
              <v-row class="container-visit d-flex flex-column px-4">
                <v-text-field
                  label="Data"
                  filled
                  dense
                  background-color="map-deep-merge gray"
                  type="date"
                ></v-text-field>

                <v-text-field
                  label="Horário"
                  filled
                  dense
                  background-color="map-deep-merge gray"
                  type="time"
                ></v-text-field>
              </v-row>
              <button class="button alt mt-8 text-uppercase text-white">
                enviar
              </button>
            </v-form>
          </v-card>

          <v-card class="mb-6 d-none"
                  v-if="store_usuario.autenticado && store_usuario.tipo_cliente == 'USUARIO'">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Informações Técnicas</h2></v-card-title
            >
            <v-card-text>
              <p>
                Clique no link abaixo para baixar o PDF completo com as especificações técnicas do empreendimento
                <br>
                <br>
                <v-layout justify-center>
                  <v-btn
                    class="ml-20"
                    v-on:click="abre_PDF()"
                    dark
                    primary
                    color="primary"
                  >
                    <v-icon class="eye-icon mr-3"> mdi-eye </v-icon>
                    INFORMAÇÃO TÉCNICA
                  </v-btn>
                </v-layout>                  
              </p>
            </v-card-text>
          </v-card>

          <v-card class="mb-6 d-none"
                  v-if="store_usuario.autenticado && store_usuario.tipo_cliente == 'USUARIO'">
              <v-card-title>
                <h2 class="h5 font-weight-bold">
                  Informações de contato
                </h2>
              </v-card-title>
            <v-card-text>
            <v-list class="pt-0 px-5">
              <v-list-item class="pl-0">
                <v-icon color="blue darken-4" class="mr-2"
                  >mdi-map-marker</v-icon
                >
                <v-list-item-subtitle class="in-p body-2">
                  R. Floriano Peixoto, 2287<br>Centro, Franca - SP
                </v-list-item-subtitle>
              </v-list-item>

              <v-list-item class="pl-0">
                <v-icon color="blue darken-4" class="mr-2">mdi-phone</v-icon>
                <v-list-item-subtitle class="in-p body-2"
                  >(16) 3752-0077</v-list-item-subtitle
                >
              </v-list-item>

              <v-list-item class="px-0">
                <v-icon color="blue darken-4" class="mr-2">mdi-email</v-icon>
                <v-list-item-subtitle class="in-p ti body-2">
                  atendimento@parraimobiliaria.com
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            </v-card-text>
          </v-card>

          <!-- Especiais do Dia/ OCULTO -->
          <v-card class="d-none">
            <v-card-title
              ><h2 class="h5 font-weight-bold">Especiais do dia</h2>
            </v-card-title>

            <div>
              <v-divider class="mt-0 mx-5 mb-6"></v-divider>

              <div class="px-5 pb-5">
                <v-img
                  max-height="300"
                  max-width="100%"
                  src=""
                  alt=""
                >
                </v-img>
              </div>
            </div>
          </v-card>
          <!-- Fim Especiais do Dia/ OCULTO -->
        </v-col>
      </v-row>
    </v-container>
    
    <!-- DIALOG - Nova Negociacao ------------------------------------------------->
    <v-container v-if="store_ModalNegociacao.dialog_novo_lead">
      <v-dialog
          v-model="store_ModalNegociacao.dialog_novo_lead"
          transition="dialog-bottom-transition"
          max-width="440"
        >
        <v-card>
          <ModalNegociacao />
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOG --------------------------------------------------------------->

    <!-- <Footer /> -->
  </div>
</template>

<script>
import store_site from "../../store/store_site";
import store_usuario from "../../store/store_usuario";
import store_EmpreendMapa from "../EmpreendMapa/store_EmpreendMapa";
import store_Empreendimentos from "./store_Empreendimentos";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_BACKGROUND } from "../../services/constantes"
import { Autenticado } from "../../services/autenticacao";
import ModalNegociacao from "../ModalNegociacao/ModalNegociacao.vue";

import { API } from "../../services/API";
import { EventBus } from "../../services/eventbus";
import { fromLonLat, transformExtent } from "ol/proj.js";

import MapaLotes from "../EmpreendMapa/MapaLotes.vue";
import EmpreendimentosGaleria from "./EmpreendimentosGaleria";
// import Footer from "../components/Footer.vue";

import { formatNumber } from "../../services/funcoes";
// import store_Negociacoes from "../Negociacoes/store_Negociacoes";

export default {
  name: "EmpreendimentoDetalhe",

  components: {
    MapaLotes,
    EmpreendimentosGaleria,
    ModalNegociacao,
  },

  data() {
    return {
      store_site            : store_site,
      store_usuario         : store_usuario,
      store_Empreendimentos : store_Empreendimentos,
      store_EmpreendMapa    : store_EmpreendMapa,
      store_ModalNegociacao : store_ModalNegociacao,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,
      COR_SECUNDARIA_2      : COR_SECUNDARIA_2,
      COR_BACKGROUND        : COR_BACKGROUND,

      formatNumber          : formatNumber,

      // resultSelecionado: {},
      resultSimilares       : [],
      filtro                : "",

      // -- Tabela --------------------
      search: "",
      quadra: "",
      lote: "",
      area: "",
      valor: "",
      pre_filtro_area: store_site.pre_filtro_area,
      pre_filtro_valor: store_site.pre_filtro_valor,
      selectedCategory: "",
    };
  },

  async created() {
    // Buscando Empreendimento Selecionado
    await this.busca_Empreendimento(
      { params: { cod_empreendimento: this.$route.params.cod_empreendimento } },
      "SELECIONADO"
    );
  },

  mounted() {
    // Buscando Empreendimentos Similares
    // this.busca_Empreendimento({ params: { filtro: "" } }, "SIMILARES");
  },

  methods: {

    NovoLead() {
      this.store_ModalNegociacao.dados                    = {};
      this.store_ModalNegociacao.dados.cod_empreendimento = this.store_Empreendimentos.empreend_selecionado.cod_empreendimento;
      this.store_ModalNegociacao.dados.empreend_nome      = this.store_Empreendimentos.empreend_selecionado.empreend_nome;
      this.store_ModalNegociacao.dados.empreend_logo      = this.store_Empreendimentos.empreend_selecionado.empreend_logo;
      this.store_ModalNegociacao.dados.imovel_tipo        = {};
      this.store_ModalNegociacao.dados.imovel_tipo.nome   = this.store_Empreendimentos.empreend_selecionado.empreend_tipo.nome;
      this.store_ModalNegociacao.dados.cidade             = this.store_Empreendimentos.empreend_selecionado.empreend_municipio.nome +
                                                            '-' +
                                                            this.store_Empreendimentos.empreend_selecionado.empreend_municipio.uf;

      this.store_ModalNegociacao.acao   = 'LEAD';
      this.store_ModalNegociacao.step   = 'ModalSelecaoPessoa';
      this.store_ModalNegociacao.dialog_novo_lead = true;
    },

    async busca_Empreendimento(p_params = {}, p_result) {
      try {

        const response = await API.get("empreendimento_com_filtro", p_params);
        // console.log("empreendimento_com_filtro", response)
        if (response) {
          if (response.data.result.data == "Não possui dados") {
            if (p_result == "SIMILARES") this.resultSimilares = [];
            if (p_result == "SELECIONADO")
              this.store_Empreendimentos.empreend_selecionado = {};
          } else {
            if (p_result == "SIMILARES") {
              this.resultSimilares = response.data.result.data;
              //console.log("this.resultSimilares", this.resultSimilares)
            }
            if (p_result == "SELECIONADO") {
              // Tentado pegar a Quadra/lote aoós ser montada no MapaLotes
              var quadras = [];
              var lotes = [];

              if (this.store_Empreendimentos.empreend_selecionado.quadras)
                quadras = this.store_Empreendimentos.empreend_selecionado.quadras;
              if (this.store_Empreendimentos.empreend_selecionado.lotes)
                lotes = this.store_Empreendimentos.empreend_selecionado.lotes;
              var lo_Aux = response.data.result;
              lo_Aux.quadras = quadras;
              lo_Aux.lotes = lotes;
              this.store_Empreendimentos.empreend_selecionado = lo_Aux;
              //console.log("lo_Aux", lo_Aux)
              this.store_Empreendimentos.fotos_galeria = lo_Aux.empreend_imagem;
            }
          }
        }
      } catch (err) {
        //console.log("err", err);
      }
    },

    flyTo(coord, rotacao, zoom) {
      EventBus.$emit("flyTo", coord, rotacao, zoom);
    },

    // MapaEmpreendimentos_AtualizaPoligonos(array_cod_empreendimentos) {
    //   EventBus.$emit("MapaEmpreendimentos_AtualizaPoligonos", array_cod_empreendimentos);
    // },

    fromLonLat(coord) {
      return fromLonLat(coord);
    },

    Autenticado: async function() {
      const ls_Autenticado = await Autenticado();
      this.store_ModalNegociacao.currentTransition    = 'next';
      this.store_ModalNegociacao.contato_selecionado  = [];
      this.store_ModalNegociacao.pessoa_selecionado   = [];

      // Entrando em Seleção de Pagamento para cliente final ou seleção de cliente para usuários/vendedores  -->
      if (this.store_usuario.tipo_cliente == "CLIENTE FINAL") {
        this.store_ModalNegociacao.step = 'ModalSelecaoPagamento';
      }
      else {
        if (store_ModalNegociacao.acao == 'SITUACAO')
          this.store_ModalNegociacao.step = 'ModalSituacaoImovel';
        else if (store_ModalNegociacao.acao == 'LEAD')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa'; //'ModalReservaConfirmacao';
        else if (store_ModalNegociacao.acao == 'RESERVA')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa'; //'ModalReservaConfirmacao';
        else if (store_ModalNegociacao.acao == 'PROPOSTA')
          this.store_ModalNegociacao.step = 'ModalSelecaoPessoa';
        else if (store_ModalNegociacao.acao == 'ORCAMENTO')
          this.store_ModalNegociacao.step = 'ModalSelecaoContato';
      }

      this.store_Login.currentTransition='next';
      this.store_Login.step = 'LoginEntrar';
    },

    abre_PDF() {
      window.open('https://parra.younect.com.br/informacao_tecnica_32.pdf', '_blank', 'fullscreen=yes');
      return false;
    }
  },

  computed: {
    headers() {
      return [
        {
          text: "Quadra",
          sortable: false,
          value: "quadra",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["quadra"].toLowerCase());
          },
        },
        {
          text: "Lote",
          value: "lote",
          //filter: value => {
          //  if (!this.lote) return true;
          //    return value < parseInt(this.calories);
          //}
          filter: (f) => {
            return (f + "").toLowerCase().includes(this["lote"].toLowerCase());
          },
        },
        {
          text: "Área",
          value: "area",
          //          filter: value => {
          //console.log("this.selectedCategory", this.selectedCategory)
          //            if (!this.selectedCategory == null)
          //              return true;
          //            console.log("value", parseFloat(this.pre_filtro_area[this.selectedCategory].min), parseFloat(this.pre_filtro_area[this.selectedCategory].max));
          //            return (value >= parseFloat(this.pre_filtro_area[this.selectedCategory].min)) && (value <= parseFloat(this.pre_filtro_area[this.selectedCategory].min));
          //          }
          filter: (f) => {
            return (f + "").toLowerCase().includes(this["area"].toLowerCase());
          },
        },
        {
          text: "Valor",
          value: "valor",
          filter: (value) => {
            if (!this.valor) return true;
            return (
              value <= parseInt(this.valor) + 10000 &&
              value >= parseInt(this.valor) - 10000
            );
          },
          //filter: f => { return ( f + '' ).toLowerCase().includes( this[ 'valor' ].toLowerCase() ) }
        },
      ];
    },
  },

  beforeMount() {
    //this.store_Empreendimentos.empreend_selecionado.cod_empreendimento = this.$route.params.cod_empreendimento;
  },
};

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
</script>

<style scoped>
*,
html {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none !important;
}

  #EmpreendimentoDetalhe {
    background: #F6F6F6;
  }


@media (max-width: 768px) {
  #EmpreendimentoDetalhe {
    padding-right: 20px; 
    padding-left: 20px;
  }

  .first-row {
    margin-top: 0px !important;
  }
}

@media(max-width: 599px) {
    #EmpreendimentoDetalhe {
    padding-right: initial; 
    padding-left: initial;
  }
}

div .main-title,
.metreage {
  font-weight: 600;
  font-size: 1.2rem !important;
  font-family: "Roboto", sans-serif;
}

.h5 {
    font-weight: 500;
    font-size: 20px !important;
    font-family: "Roboto", sans-serif !important;
}

.icon-address {
  font-size: 22px !important;
  color: #274abb;
}

.hidden-no-xs {
  display: none;
}

.facilities {
  color: rgba(0, 0, 0, 0.87);
}

@media (max-width: 599px) {
  .title-wrapper {
    display: flex;
    flex-direction: column;
  }

  .main-title::after {
    float: right;
    height: 30px;
    display: flex;
    align-items: center;
  }
}

.button {
  width: 100%;
  height: 2.2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 0.9rem !important;
  background: #274abb;
}

/*-----------------------------------*/
/*------ MAPA ------*/
/*-----------------------------------*/
.card-map {
  position: relative;
  box-sizing: border-box;
}

@media (max-width: 599px) {
  .card-map {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .title-map {
    padding-left: 16px !important;
  }

  .p-title-sm {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.container-map {
  width: 100%;
  height: 500px;
}
.theme--light.v-data-table .v-data-footer {
  background: aqua !important;
}

table {
  background-color: aqua !important;
}

.v-data-footer__select {
  display: none !important;
  content: "Linhas por página" !important;
  background: aqua !important;
}

/*-----------------------------------*/
/*------ VIDEO ------*/
/*-----------------------------------*/
.container {
  padding: 0px !important;
}

.container {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.panel {
  height: 400px;
}

.panel::before {
  padding-top: 56.25%;
}

.panel::before {
  display: block;
  content: "";
}

.container .content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (max-width: 599px) {
  .v-card iframe {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

/*-----------------------------------*/
/*------ GENERAL BREAKPOINTS ------*/
/*-----------------------------------*/
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200px;
  }
}

@media (max-width: 768px) {
  .container-visit {
    flex-direction: row !important;
  }

  .container-visit .v-text-field {
    width: 30%;
    margin: 0 10px;
  }
}

@media (max-width: 599px) {
  .page-container {
    padding: 0 15px !important;
  }

  .my-16 {
    margin-top: 28px !important;
    margin-bottom: 0px !important;
  }

  .container-visit {
    flex-direction: column !important;
  }

  .container-visit .v-text-field {
    width: 100%;
    margin: 0;
  }

  .v-card__subtitle {
    padding-top: 0px;
  }

  .v-list .wrapper-details-1,
  .wrapper-details-2,
  .wrapper-details-3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .wrapper-details-1 .v-list-item__subtitle,
  .wrapper-details-2 .v-list-item__subtitle {
    margin-bottom: 5px;
  }

  .wrapper-details-2,
  .wrapper-details-4 {
    display: none !important;
  }

  .hidden-no-xs {
    display: block;
  }
}

/*-----------------------------------*/
/*------ EMPREENDIMENTOS SIMILARES ------*/
/*-----------------------------------*/
.box {
  box-sizing: border-box !important;
}

.card-column {
  box-sizing: border-box !important;
  padding: 10px !important;
  display: flex;
  justify-content: center;
}

@media (max-width: 599px) {
  .card-column {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

.box-card {
  width: 100% !important;
}

.card-row {
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  padding: 16px;
}

.card-title {
  font-size: 17px !important;
  font-weight: 600;
  text-transform: capitalize !important;
  color: #444;
}

.homes-tag.featured {
  height: 30px;
  font-weight: 600;
  background: #18ba60;
  color: #fff;
}

@media (max-width: 1024px) {
  .homes-tag span {
    display: none;
  }
}

@media (max-width: 768px) {
  .homes-tag span {
    display: inline-block;
    background: #18ba60;
  }
}

.homes-tag.featured {
  margin-bottom: 15px;
}

.homes-tag.sale {
  width: 85px;
  height: 30px;
  font-weight: 600;
  background: #e54242;
  color: #fff;
}

.homes-img {
  height: 100%;
}

.footer {
  background: none;
  border-top: 1px solid #ebebeb;
  padding-top: 0.2rem;
  font-size: 13px;
  color: #666;
}

.footer {
  flex-direction: column;
}

.footer-value {
  flex-direction: row !important;
}

.font-small {
  margin-right: 5px;
}

@media (max-width: 768px) {
  .footer {
    flex-direction: row;
  }

  .footer-value {
    flex-direction: column !important;
  }
}

@media (max-width: 599px) {
  .footer {
    flex-direction: column;
  }

  .footer-value {
    flex-direction: row !important;
  }
}

.footer-value {
  font-family: "Lato", sans-serif;
  font-size: 17px;
}

.font-small {
  font-size: 15px !important;
}

.v-data-table {
  height: 480px !important;
  overflow-y: auto !important;
}

.v-select__selection {
  display: none !important;
}

@media (max-width: 599px) {
  .galery {
    margin-top: 38px!important;
  }

  .container-title {
    flex-direction: column;
    padding: 0px 20px 12px;
  }

  .container-title div:first-child {
  margin-left: 0px !important;
}
}
</style>
